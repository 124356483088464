<template>
	<div>
		<v-tabs v-model="tabs" :background-color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-3'">
			<v-tab>Монтажни услуги</v-tab>
		</v-tabs>
		<v-tabs-items v-model="tabs">
			<v-tab-item>
				<data-loader :callback="() => model.fetch('get', {keys: ['JobRequestProcessorUserIDs']})">
					<template #loading>
						<v-progress-linear indeterminate rounded />
					</template>
					<template #default="{data,reload}">
						<v-container fluid v-if="data">
							<v-card elevation="1" :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-3'" max-width="800">
								<v-toolbar flat dense :color="$vuetify.theme.dark ? 'grey darken-2' : 'grey lighten-2'">
									<v-toolbar-title>Оператори за обработване на извършени дейности</v-toolbar-title>
									<v-spacer/>
									<btn-loading-promise
										:click="() => model.fetch('update', {}, {JobRequestProcessorUserIDs: data?.JobRequestProcessorUserIDs})"
										@ready="reload"
									>
										<template #default="{click,loading}">
											<v-btn small @click="click" :loading="loading">
												<v-icon left>mdi-content-save</v-icon>
												Запис
											</v-btn>
										</template>
									</btn-loading-promise>
								</v-toolbar>
								<v-card-text>
									<v-autocomplete multiple chips deletable-chips :items="beUsers" v-model="data.JobRequestProcessorUserIDs" dense outlined hide-details="auto" />
								</v-card-text>
							</v-card>
						</v-container>
					</template>
				</data-loader>
			</v-tab-item>
		</v-tabs-items>
	</div>
</template>

<script>
import {ITTIModelBase} from "@/ittijs/ITTIModel";
import BtnLoadingPromise from "@/ittijs/components/BtnLoadingPromise.vue";
import DataLoader from "@/ittijs/components/DataLoader.vue";

class Model extends ITTIModelBase {
	constructor() {
		super('Settings');
	}
}

const model = new Model;

export default {
	components : {DataLoader, BtnLoadingPromise},
	created() {
		model.fetch('getBEUsers').then(list => this.beUsers = list);
	},
	data() {
		return {
			tabs: null,
			model,
			beUsers: [],
		}
	},
}
</script>